*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .9em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose > :where(ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose > :where(ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose > :where(ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose > :where(ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-slate {
  --tw-prose-body: #334155;
  --tw-prose-headings: #0f172a;
  --tw-prose-lead: #475569;
  --tw-prose-links: #0f172a;
  --tw-prose-bold: #0f172a;
  --tw-prose-counters: #64748b;
  --tw-prose-bullets: #cbd5e1;
  --tw-prose-hr: #e2e8f0;
  --tw-prose-quotes: #0f172a;
  --tw-prose-quote-borders: #e2e8f0;
  --tw-prose-captions: #64748b;
  --tw-prose-code: #0f172a;
  --tw-prose-pre-code: #e2e8f0;
  --tw-prose-pre-bg: #1e293b;
  --tw-prose-th-borders: #cbd5e1;
  --tw-prose-td-borders: #e2e8f0;
  --tw-prose-invert-body: #cbd5e1;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #94a3b8;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #94a3b8;
  --tw-prose-invert-bullets: #475569;
  --tw-prose-invert-hr: #334155;
  --tw-prose-invert-quotes: #f1f5f9;
  --tw-prose-invert-quote-borders: #334155;
  --tw-prose-invert-captions: #94a3b8;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #cbd5e1;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #475569;
  --tw-prose-invert-td-borders: #334155;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.isolate {
  isolation: isolate;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clear-left {
  clear: left;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-screen {
  height: 100vh;
}

.w-44 {
  width: 11rem;
}

.w-32 {
  width: 8rem;
}

.w-full {
  width: 100%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/2 {
  width: 50%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.flex-none {
  flex: none;
}

.flex-auto {
  flex: auto;
}

.flex-1 {
  flex: 1;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.border-collapse {
  border-collapse: collapse;
}

.list-disc {
  list-style-type: disc;
}

.columns-2 {
  columns: 2;
}

.break-inside-avoid-column {
  break-inside: avoid-column;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-thin {
  grid-template-columns: 1fr 3fr;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse) );
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse) );
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse) );
}

.divide-slate-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-divide-opacity) );
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity) );
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity) );
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity) );
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity) );
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity) );
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity) );
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity) );
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity) );
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity) );
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity) );
}

.bg-\[\#DBEDDB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(219 237 219 / var(--tw-bg-opacity) );
}

.bg-\[\#FDECC8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 236 200 / var(--tw-bg-opacity) );
}

.bg-\[\#FADEC9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 222 201 / var(--tw-bg-opacity) );
}

.bg-\[\#FFE2DD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 226 221 / var(--tw-bg-opacity) );
}

.bg-\[\#EEE0DA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 224 218 / var(--tw-bg-opacity) );
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity) );
}

.bg-\[\#D4E5EF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 229 239 / var(--tw-bg-opacity) );
}

.p-4 {
  padding: 1rem;
}

.p-3 {
  padding: .75rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-extrabold {
  font-weight: 800;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.leading-8 {
  line-height: 2rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-tight {
  line-height: 1.25;
}

.leading-loose {
  line-height: 2;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity) );
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity) );
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity) );
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-1 {
  text-underline-offset: 1px;
}

.opacity-60 {
  opacity: .6;
}

.opacity-80 {
  opacity: .8;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-gray-600 {
  --tw-shadow-color: #4b5563;
  --tw-shadow: var(--tw-shadow-colored);
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[https\:\/\/samdking\.co\.uk\/blog\/2022-goals\/\] {
  https:  / / samdking. co. uk / blog / 2022 - goals / ;
}

.\[https\:\/\/samdking\.co\.uk\/feed\/2022\] {
  https:  / / samdking. co. uk / feed / 2022;
}

.\[https\:\/\/samdking\.co\.uk\/blog\/2023-goals\/\] {
  https:  / / samdking. co. uk / blog / 2023 - goals / ;
}

.\[https\:\/\/www\.healthline\.com\/nutrition\/6-health-benefits-of-hemp-seeds\] {
  https:  / / www. healthline. com / nutrition / 6 - health-benefits-of-hemp-seeds;
}

.\[https\:\/\/vegetarian\.lovetoknow\.com\/Seaweed_Nori_Nutrition\] {
  https:  / / vegetarian. lovetoknow. com / Seaweed Nori Nutrition;
}

.\[https\:\/\/www\.vegansociety\.com\/resources\/nutrition-and-health\/life-stages\/under-fives\] {
  https:  / / www. vegansociety. com / resources / nutrition-and-health / life-stages / under-fives;
}

.\[https\:\/\/www\.healthline\.com\/nutrition\/7-plant-sources-of-omega-3s\] {
  https:  / / www. healthline. com / nutrition / 7 - plant-sources-of-omega-3s;
}

.link {
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity) );
  text-underline-offset: 1px;
  text-decoration-line: underline;
}

.link:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

.link:active {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
}

.tasks-list span {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity) );
  border-radius: .125rem;
  margin-right: .25rem;
  padding: .25rem .5rem;
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  font-style: italic;
}

.tasks-list span:before {
  content: "#";
}

.hide-football .football, .yearly-stats .other {
  display: none;
}

.hide-football .yearly-stats .other {
  display: block;
}

.hide-football .yearly-stats .all {
  display: none;
}

.year-weeks a {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity) );
  text-align: center;
  padding: .75rem;
  line-height: 1.75rem;
  display: block;
}

.year-weeks a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.year-weeks .completed {
  --tw-bg-opacity: 1;
  background-color: rgb(38 194 129 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.year-weeks .completed:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 174 116 / var(--tw-bg-opacity) );
}

.year-weeks .future {
  pointer-events: none;
  opacity: .7;
}

.yearly-stats .stat {
  --tw-border-opacity: 1;
  border-bottom-width: 4px;
  border-bottom-color: rgb(245 158 11 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  flex: 1;
  padding: .5rem .75rem;
}

@media (min-width: 640px) {
  .yearly-stats .stat {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .yearly-stats .stat {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .yearly-stats .stat {
    padding: .75rem 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.yearly-stats a:hover {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(245 158 11 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity) );
}

.years-nav a {
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity) );
  text-underline-offset: 1px;
  text-decoration-line: underline;
  display: inline-block;
}

.years-nav .active {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity) );
  border-radius: .5rem;
  padding: .25rem .5rem;
  text-decoration-line: none;
}

.week {
  content-visibility: auto;
  contain-intrinsic-height: auto 650px;
}

.token.prefix.unchanged, .token.prefix.inserted, .token.prefix.deleted {
  user-select: none;
}

pre > code .token.deleted:not(.prefix), pre > code .token.deleted:not(.prefix) {
  color: inherit;
  background-color: #f003;
  padding: 1px 0;
  display: block;
}

pre > code .token.inserted:not(.prefix), pre > code .token.inserted:not(.prefix) {
  color: inherit;
  background-color: #00ff8033;
  padding: 1px 0;
  display: block;
}

.blog-tag {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity) );
  text-transform: uppercase;
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  border-radius: .375rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  display: inline-block;
}

.blog-tag:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity) );
}

.bright-aside {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity) );
  margin: 2rem -1.5rem;
  padding: 1.25rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.625;
}

.bright-aside strong {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  padding-left: .25rem;
  padding-right: .25rem;
}

.marker\:text-slate-500 ::marker {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
}

.marker\:text-slate-500::marker {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
}

.before\:mr-1:before {
  content: var(--tw-content);
  margin-right: .25rem;
}

.before\:text-slate-500:before {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
}

.before\:content-\[\'“\'\]:before {
  --tw-content: "“";
  content: var(--tw-content);
}

.after\:ml-1:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.after\:text-slate-500:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
}

.after\:content-\[\'”\'\]:after {
  --tw-content: "”";
  content: var(--tw-content);
}

.first\:pt-2:first-child {
  padding-top: .5rem;
}

.hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity) );
}

.hover\:text-slate-200:hover {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity) );
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.prose-h1\:mb-4 :is(:where(h1):not(:where([class~="not-prose"] *))) {
  margin-bottom: 1rem;
}

.prose-h2\:mt-6 :is(:where(h2):not(:where([class~="not-prose"] *))) {
  margin-top: 1.5rem;
}

.prose-h2\:mb-4 :is(:where(h2):not(:where([class~="not-prose"] *))) {
  margin-bottom: 1rem;
}

.prose-a\:tracking-wide :is(:where(a):not(:where([class~="not-prose"] *))) {
  letter-spacing: .025em;
}

.prose-a\:text-amber-700 :is(:where(a):not(:where([class~="not-prose"] *))) {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity) );
}

.prose-a\:underline :is(:where(a):not(:where([class~="not-prose"] *))) {
  text-decoration-line: underline;
}

.prose-a\:underline-offset-1 :is(:where(a):not(:where([class~="not-prose"] *))) {
  text-underline-offset: 1px;
}

@media (min-width: 640px) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:max-w-full {
    max-width: 100%;
  }

  .sm\:columns-2 {
    columns: 2;
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:float-left {
    float: left;
  }

  .md\:m-8 {
    margin: 2rem;
  }

  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mr-12 {
    margin-right: 3rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:basis-1\/4 {
    flex-basis: 25%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:overflow-visible {
    overflow: visible;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:leading-relaxed {
    line-height: 1.625;
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
}

@media (min-width: 1024px) {
  .lg\:float-right {
    float: right;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-calendar {
    grid-template-columns: repeat(13, minmax(0, 1fr));
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:leading-relaxed {
    line-height: 1.625;
  }
}

@media (min-width: 1280px) {
  .xl\:mt-8 {
    margin-top: 2rem;
  }

  .xl\:max-w-3xl {
    max-width: 48rem;
  }

  .xl\:max-w-4xl {
    max-width: 56rem;
  }

  .xl\:grid-cols-layout {
    grid-template-columns: 1fr 2fr;
  }
}

/*# sourceMappingURL=tailwind.css.map */
