@tailwind base;
@tailwind components;
@tailwind utilities;

.link {
  @apply text-amber-700 underline-offset-1 underline tracking-wide hover:text-slate-700 active:text-slate-500;
}

.tasks-list span {
  @apply font-serif italic text-slate-600 px-2 mr-1 py-1 bg-slate-200 rounded-sm;
}

.tasks-list span:before {
  content: '#';
}

.hide-football .football {
  @apply hidden;
}

.yearly-stats .other {
  @apply hidden;
}

.hide-football .yearly-stats .other {
  @apply block;
}

.hide-football .yearly-stats .all {
  @apply hidden;
}

.year-weeks a {
  @apply bg-slate-300 leading-7 hover:bg-slate-400 hover:text-white hover:text-lg block text-center p-3;
}

.year-weeks .completed {
  @apply bg-[#26C281] text-white hover:bg-[#22ae74];
}

.year-weeks .future {
  @apply opacity-70 pointer-events-none;
}

.yearly-stats .stat {
  @apply border-b-4 border-b-amber-500 py-2 px-3 lg:py-3 lg:px-4 bg-slate-700 text-white sm:text-lg md:text-xl lg:text-2xl flex-1
}

.yearly-stats a {
  @apply hover:border-b-amber-500 hover:bg-slate-500
}

.years-nav a {
  @apply text-amber-700 tracking-wide inline-block underline-offset-1 underline
}

.years-nav .active {
  @apply text-slate-800 bg-slate-200 rounded-lg py-1 px-2 no-underline
}

.week {
  content-visibility: auto;
  contain-intrinsic-height: auto 650px;
}

.token.prefix.unchanged,
.token.prefix.inserted,
.token.prefix.deleted {
  -webkit-user-select: none;
  user-select: none;
}

pre > code .token.deleted:not(.prefix),
pre > code .token.deleted:not(.prefix) {
  background-color: rgba(255, 0, 0, .2);
  color: inherit;
  display: block;
  padding: 1px 0;
}

pre > code .token.inserted:not(.prefix),
pre > code .token.inserted:not(.prefix) {
  background-color: rgba(0, 255, 128, .2);
  color: inherit;
  display: block;
  padding: 1px 0;
}

.blog-tag {
  @apply inline-block px-2 text-white text-sm uppercase font-bold tracking-wide rounded-md bg-amber-600 hover:bg-slate-600
}

.bright-aside {
  @apply bg-orange-200 my-8 -mx-6 px-6 py-5 text-amber-900 text-xl leading-relaxed
}

.bright-aside strong {
  @apply text-white bg-orange-500 px-1
}
